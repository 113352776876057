/* Date picker */

.date_picker {
  padding: 0;
}

.date_picker input {
  width: 100px;
  height: 56px;
  text-align: center;
}

.date_picker input :focus {
  box-sizing: border-box;
}
