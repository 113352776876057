@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  scrollbar-gutter: stable;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}




.button-container {
  width: 152px;
  height: 152px;
  filter: drop-shadow(rgba(0, 0, 0, 0.36) 0px 3px 6px);
  position: relative;
}

.button-container-small {
  width: 76px;
  height: 76px;
  filter: drop-shadow(rgba(0, 0, 0, 0.36) 0px 3px 6px);
  position: relative;
}

.button-container .inner1 {
  position: absolute;
  width: 152px;
  height: 152px;
  left: 0px;
  top: 0px;
  border-radius: 50%;

  box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 8px inset;
}

.button-container-small .inner1 {
  position: absolute;
  width: 76px;
  height: 76px;
  left: 0px;
  top: 0px;
  border-radius: 50%;

  box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 8px inset;
}


.button-container .inner1 .inner2 {
  position: absolute;
  width: 140px;
  height: 140px;
  left: 6px;
  top: 6px;
  border-radius: 50%;
  
}

.button-container-small .inner1 .inner2 {
  position: absolute;
  width: 73.5px;
  height: 73.5px;
  left: 1px;
  top: 1px;
  border-radius: 50%;
  
}

.inner2:hover {
  background: white;
}

.button-container .inner1 .inner2 > button.start {
  background: rgb(108, 100, 186);
  box-shadow: rgba(108, 100, 186, 0.72) 0px 0px 9px, rgba(0, 0, 0, 0.5) 0px 0px 27px inset;
}
.button-container .inner1 .inner2 > button.stop {
  background: rgb(177, 41, 0);
  box-shadow: rgba(177, 41, 0, 0.72) 0px 0px 9px, rgba(0, 0, 0, 0.5) 0px 0px 27px inset;
}

.button-container .inner1 .inner2 > button {
  position: absolute;
  width: 131px;
  height: 131px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
  border: medium none;
  user-select: none;
  background: rgb(25, 61, 125);
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  font-size: 1.0em;
  justify-content: center;
  flex-direction: column;
  row-gap: 8px;
  color: rgb(255, 255, 255);
  box-shadow: rgba(80, 129, 255, 0.72) 0px 0px 9px, rgba(0, 0, 0, 0.5) 0px 0px 27px inset;
}

.button-container-small .inner1 .inner2 > button {
  position: absolute;
  width: 65.5px;
  height: 65.5px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
  border: medium none;
  user-select: none;
  background: rgb(25, 61, 125);
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  font-size: 1.0em;
  justify-content: center;
  flex-direction: column;
  row-gap: 8px;
  color: rgb(255, 255, 255);
  box-shadow: rgba(80, 129, 255, 0.72) 0px 0px 9px, rgba(0, 0, 0, 0.5) 0px 0px 27px inset;
}















.select-wrapper {
  position: relative;
  width: 200px;
}

.select-wrapper::before {
  /*color: #fff;*/
  font-size: 20px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 10px;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: white;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 5px 1px rgba(0,0,0,.3);
  /*color: #fff;*/
  cursor: pointer;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 16px;
  height: 40px;
  outline: none;
  padding-left: 10px;
  width: 100%;
  text-transform: uppercase;
}

select option {
  color: #666;
  text-transform: uppercase;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
}

select::-ms-expand {
  display: none;
}

select:focus::-ms-value {
  background-color: transparent;
}